import InfiniteScroll from "react-infinite-scroll-component";
import { AdminAlert } from "../../../../models/Entities";
import MergeTable from "../../../shared/MergeTable";
import { Card } from "@merge-api/merge-javascript-shared";
import AlertDashboardTableRow from "./AlertDashboardTableRow";
import TableSkeletonLoader from "../../../shared/TableSkeletonLoader";
import { ALERT_CATEGORIES, TABLE_TYPE_COLUMN_ORDERINGS } from "./utils/constants";
import AlertDashboardColumnTitle from "./AlertDashboardColumnTitle";
import AlertDashboardEmptyTable from "./AlertDashboardEmptyTable";

type AlertDashboardTableProps = {
  alerts: AdminAlert[] | undefined;
  tableType: ALERT_CATEGORIES;
  onNext: () => void;
  hasNext: boolean;
  isLoading: boolean;
  usingSearchFilters: boolean;
  activeAlertID?: string;
};

const AlertDashboardTable = ({
  alerts = [],
  tableType,
  onNext,
  hasNext,
  isLoading,
  usingSearchFilters,
  activeAlertID,
}: AlertDashboardTableProps) => {
  const columnOrder = TABLE_TYPE_COLUMN_ORDERINGS[tableType] || [];
  return (
    <Card className="p-0">
      <InfiniteScroll next={onNext} loader={null} hasMore={hasNext} dataLength={alerts.length}>
        <MergeTable
          hover
          hasMarginBottom={false}
          hasMorePaddingOnFirstElement
          header={
            <>
              {columnOrder.map((column) => (
                <AlertDashboardColumnTitle column={column} />
              ))}
            </>
          }
          borderBottom="1px solid rgb(237, 242, 249)"
          content={
            isLoading && !alerts.length ? (
              <TableSkeletonLoader cols={columnOrder.length} rows={8} size="large" />
            ) : (
              <>
                {!alerts.length ? (
                  <AlertDashboardEmptyTable
                    columnCount={columnOrder.length}
                    usingSearchFilters={usingSearchFilters}
                  />
                ) : (
                  alerts.map((alert) => (
                    <AlertDashboardTableRow
                      key={alert.id}
                      alert={alert}
                      tableType={tableType}
                      activeAlertID={activeAlertID}
                    />
                  ))
                )}
                {isLoading && (
                  <TableSkeletonLoader cols={columnOrder.length} rows={1} size="large" />
                )}
              </>
            )
          }
        />
      </InfiniteScroll>
    </Card>
  );
};

export default AlertDashboardTable;
