import { Badge } from "@merge-api/merge-javascript-shared";
import { firstLetterUpperCase } from "../../../../utils";
import { SeverityLevel } from "./utils/constants";

interface SeverityCellProps {
  severity: SeverityLevel;
}

const SeverityColorMap: Record<SeverityLevel, "red" | "orange" | "yellow" | "redStrong"> = {
  [SeverityLevel.LOW]: "yellow",
  [SeverityLevel.MEDIUM]: "orange",
  [SeverityLevel.HIGH]: "red",
  [SeverityLevel.CRITICAL]: "redStrong",
};

const SeverityCell = ({ severity }: SeverityCellProps) => {
  const severityColor = severity ? SeverityColorMap[severity] : SeverityColorMap[SeverityLevel.LOW];
  return (
    <Badge color={severityColor} className={`text-left-align font-medium`}>
      {firstLetterUpperCase(severity)}
    </Badge>
  );
};

export default SeverityCell;
