import { Dispatch, SetStateAction } from "react";
import { fetchWithAuth } from "../../../api-client/api_client";
import { showErrorToast } from "../../../components/shared/Toasts";

const getAlertCount = async (setAlertCount: Dispatch<SetStateAction<number | null>>) => {
  await fetchWithAuth({
    path: `analytics/alert-dashboard/count`,
    method: "GET",
    onResponse: (data) => {
      setAlertCount(data.count || 0);
    },
    onError: (error) => {
      showErrorToast(`Failed to fetch alert count: ${error}`);
    },
  });
};

export { getAlertCount };
