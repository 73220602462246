import { ButtonVariant, Menu, MenuItem, Tooltip } from "@merge-api/merge-javascript-shared";
import {
  BlueprintVersion,
  IntegrationEditorAction,
  IntegrationEditorTopControlPanelActionProps,
} from "../../../models/Blueprints";
import { useBlueprintActions } from "./hooks/useBlueprintActions";
import clsx from "clsx";

interface IntegrationEditorTopControlComboButtonProps {
  publishedStateActions: IntegrationEditorTopControlPanelActionProps[];
  blueprintVersion?: BlueprintVersion;
  isArchived?: boolean;
}

const IntegrationEditorTopControlComboButton = ({
  publishedStateActions,
  blueprintVersion,
  isArchived,
}: IntegrationEditorTopControlComboButtonProps) => {
  const { mainAction, dropdownActions } = useBlueprintActions({
    publishedStateActions,
    blueprintVersion,
    isArchived,
  });

  if (!mainAction || !blueprintVersion) return null;

  return (
    <Menu
      isComboButton
      menuPlacement="bottom-end"
      ButtonProps={{
        disabled: mainAction.isDisabled,
        variant: ButtonVariant.PrimaryBlue,
        children: mainAction.text,
        onClick: mainAction.onClick,
      }}
    >
      {dropdownActions.map((action) => {
        const isDeleteAction = action.text === IntegrationEditorAction.DELETE;

        const menuItem = (
          <MenuItem
            key={action.text}
            onClick={action.onClick}
            disabled={action.isDisabled}
            className={clsx(isDeleteAction && !action.isDisabled && "text-red-60")}
          >
            {action.text}
          </MenuItem>
        );

        return action.isDisabled && action.disabledTooltipText ? (
          <div className="flex items-start flex-col">
            <Tooltip placement="left" title={action.disabledTooltipText} key={action.text}>
              {menuItem}
            </Tooltip>
          </div>
        ) : (
          menuItem
        );
      })}
    </Menu>
  );
};

export default IntegrationEditorTopControlComboButton;
