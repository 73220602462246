import { Drawer } from "@merge-api/merge-javascript-shared";
import { AdminAlert } from "../../../../../models/Entities";
import AlertDashboardDrawerTitlePrefix from "./AlertDashboardDrawerTitlePrefix";
import AlertDashboardDrawerTitlePostfix from "./AlertDashboardDrawerPostfix";
import { getSeverity, getStatus } from "../utils/helpers";
import AlertDashboardDrawerContent from "./AlertDashboardDrawerContent";
import { ALERT_CATEGORIES } from "../utils/constants";
import DrawerAlertActionCell from "./DrawerAlertActionCell";
import { Dispatch, SetStateAction } from "react";

interface AlertDashboardDrawerProps {
  alert: AdminAlert;
  category: ALERT_CATEGORIES;
  isDrawerOpen: boolean;
  handleDrawerClose: () => void;
  updateAdminAlert: Dispatch<SetStateAction<Record<string, any>>>;
}

const AlertDashboardDrawer = ({
  alert,
  category,
  isDrawerOpen,
  handleDrawerClose,
  updateAdminAlert,
}: AlertDashboardDrawerProps) => {
  return (
    <Drawer
      open={isDrawerOpen}
      onClose={() => {
        handleDrawerClose();
      }}
      title=" "
      titlePrefix={<AlertDashboardDrawerTitlePrefix category={category} alert={alert} />}
      titlePostfix={
        <AlertDashboardDrawerTitlePostfix
          severity={getSeverity(alert.severity_level)}
          status={getStatus(alert.resolved_at, alert.silenced_at)}
        />
      }
      exitPrefix={<DrawerAlertActionCell alert={alert} updateAdminAlert={updateAdminAlert} />}
      truncateLongTitle
      anchor="right"
      sizeVariant="lg"
      className="overflow-visible"
    >
      <AlertDashboardDrawerContent alert={alert} category={category} />
    </Drawer>
  );
};

export default AlertDashboardDrawer;
