import { AdminAlert } from "../../../../../models/Entities";
import AlertDashboardDrawerSuccessRate from "./AlertDashboardDrawerSuccessRate";
import { calculateDeviation, parseErrorCodes } from "../utils/helpers";
import AlertDashboardDrawerRelatedBlueprints from "./AlertDashboardDrawerRelatedBlueprints";
import AlertDashboardDrawerExecutionDetails from "./AlertDashboardDrawerExecutionStats";
import AlertDashboardDrawerErrorCodeData from "./AlertDashboardDrawerErrorCodeData";
import { ALERT_CATEGORIES } from "../utils/constants";

interface AlertDashboardDrawerContentProps {
  alert: AdminAlert;
  category: ALERT_CATEGORIES;
}

const AlertDashboardDrawerContent = ({ alert, category }: AlertDashboardDrawerContentProps) => {
  const errorCodeCounts = parseErrorCodes(alert.additional_information);

  return (
    <div className="p-6">
      <AlertDashboardDrawerSuccessRate
        current={alert.metric_value || 0}
        overall={alert.baseline_value || 0}
        deviation={calculateDeviation(alert.metric_value, alert.baseline_value)}
        metricName={alert.metric_name}
        category={category}
      />
      <AlertDashboardDrawerRelatedBlueprints
        blueprintId={alert.blueprint_id}
        blueprintName={alert?.blueprint_name}
        relatedBlueprints={alert?.blueprint_version_ids || []}
        integrationId={alert?.integration_id || ""}
      />
      <div className="grid grid-cols-2 gap-6 mt-6">
        <AlertDashboardDrawerErrorCodeData alert={alert} errorCodeCounts={errorCodeCounts} />
        <AlertDashboardDrawerExecutionDetails
          totalSuccessful={alert?.total_success_count || 0}
          totalFailed={alert?.total_failure_count || 0}
        />
      </div>
    </div>
  );
};

export default AlertDashboardDrawerContent;
