import { AdminAlert } from "../../../../../models/Entities";

export const getKibanaBaseUrl = (): string => {
  switch (process.env.REACT_APP_MERGE_ENV) {
    case "PRODUCTION":
      return "https://kibana.access.teleport.merge.land:3080";
    case "DEVELOP":
      return "https://kibana-develop.access.teleport.merge.land:3080";
    default:
      return "localhost:3080"; // Invalid local URL for debugging
  }
};

export const constructKibanaLinkFromAlertErrorCode = (
  alert: AdminAlert,
  errorCode: string
): string => {
  const createFilterQueryString = (key: string, value: string) => {
    return `(meta:(key:${key},params:(query:'${value}'),type:phrase),query:(match_phrase:(${key}:'${value}')))`;
  };

  const filters: string[] = [createFilterQueryString("response_code", errorCode)];

  if (alert.integration_id) {
    filters.push(createFilterQueryString("integration_id", alert.integration_id));
  }

  if (alert.organization_id) {
    filters.push(createFilterQueryString("organization_id", alert.organization_id));
  }

  const filtersQueryString = `filters:!(${filters.join(",")})`;

  const url = `${getKibanaBaseUrl()}/_plugin/kibana/app/discover#/?_a=(query:(language:kuery,query:'*'),${filtersQueryString})`;
  return url;
};
