import { Container } from "react-bootstrap";
import { Tab, TabPanel, Tabs, TabsList } from "@merge-api/merge-javascript-shared";
import { useLoadAlertCounts, useLoadPaginatedAlerts } from "../hooks/useLoadPaginatedAlerts";
import AlertDashboardBadge from "./components/AlertDashboardBadge";
import { snakeCaseToFirstLetterUpperCase } from "../../../utils";
import { ALERT_CATEGORIES, AlertsData } from "./components/utils/constants";
import { useState } from "react";
import { TypeaheadComparatorClusterOption } from "@merge-api/merge-javascript-shared/dist/designSystem/molecules/Typeahead/types/types";
import AlertDashboardTable from "./components/AlertDashboardTable";
import AlertDashboardHeader from "./components/AlertDashboardHeader";
import { constructAlertQueryParamsFromSearchFilters } from "./components/utils/helpers";
import { useHistory, useParams } from "react-router-dom";
import { navigateToAlertDashboardTab } from "../../../router/RouterUtils";

type RouteParams = {
  category: string | undefined;
  alertID: string | undefined;
};

const AlertDashboard = () => {
  const alertCategories = Object.values(ALERT_CATEGORIES);
  const history = useHistory();

  // Route params
  const { category, alertID } = useParams<RouteParams>();
  const normalizedCategory = category?.toUpperCase();
  const validCategory = alertCategories.find((c) => c === normalizedCategory);
  const tabIndex = validCategory ? alertCategories.indexOf(validCategory) : 0;
  const activeCategory = validCategory ?? ALERT_CATEGORIES.INTEGRATION;

  // Search state
  const [searchFilters, setSearchFilters] = useState<TypeaheadComparatorClusterOption[]>([]);

  // If we are routing to a specific alert, enable these.
  const [showResolved, setShowResolved] = useState(!!alertID);
  const [showSilenced, setShowSilenced] = useState(!!alertID);

  // Hooks for loading paginated alerts for each category and alert counts
  const queryParams = constructAlertQueryParamsFromSearchFilters(
    searchFilters,
    showResolved,
    showSilenced
  );
  const alertsData: Record<ALERT_CATEGORIES, AlertsData> = {
    [ALERT_CATEGORIES.INTEGRATION]: useLoadPaginatedAlerts({
      alertCategory: ALERT_CATEGORIES.INTEGRATION,
      queryParams,
    }),
    [ALERT_CATEGORIES.ORGANIZATION]: useLoadPaginatedAlerts({
      alertCategory: ALERT_CATEGORIES.ORGANIZATION,
      queryParams,
    }),
    [ALERT_CATEGORIES.LINKED_ACCOUNT]: useLoadPaginatedAlerts({
      alertCategory: ALERT_CATEGORIES.LINKED_ACCOUNT,
      queryParams,
    }),
  };

  const [alertCounts, setAlertCounts] = useState<Record<ALERT_CATEGORIES, number>>(
    Object.fromEntries(Object.values(ALERT_CATEGORIES).map((category) => [category, 0])) as Record<
      ALERT_CATEGORIES,
      number
    >
  );
  useLoadAlertCounts(setAlertCounts, showResolved, showSilenced);

  const onTabChange = (_: any, newValue: string | number | null) => {
    if (typeof newValue === "string" || typeof newValue === "number") {
      const index = Number(newValue);
      if (!isNaN(index)) {
        navigateToAlertDashboardTab(history, alertCategories[index]);
      }
    }
  };

  return (
    <Container fluid className="pb-8 px-8 pt-16">
      {/* Header */}
      <h2 className="mt-10">Alerts</h2>
      <br />
      <Tabs variant="button" onChange={onTabChange} value={tabIndex}>
        <TabsList>
          {alertCategories.map((category) => (
            <Tab key={category}>
              {snakeCaseToFirstLetterUpperCase(category)}{" "}
              <AlertDashboardBadge count={alertCounts[category]} />
            </Tab>
          ))}
        </TabsList>
        <AlertDashboardHeader
          category={activeCategory}
          alertsData={alertsData[activeCategory]}
          searchFilters={searchFilters}
          setSearchFilters={setSearchFilters}
          showResolved={showResolved}
          setShowResolved={setShowResolved}
          showSilenced={showSilenced}
          setShowSilenced={setShowSilenced}
        />
        {/* Dynamic Tab Content */}
        {alertCategories.map((category) => (
          <TabPanel key={category}>
            <AlertDashboardTable
              alerts={alertsData[category]?.alerts}
              tableType={category}
              hasNext={alertsData[category]?.hasNext}
              onNext={alertsData[category]?.onNext}
              isLoading={alertsData[category]?.isLoading}
              usingSearchFilters={searchFilters.length > 0}
              activeAlertID={alertID}
            />
          </TabPanel>
        ))}
      </Tabs>
    </Container>
  );
};

export default AlertDashboard;
