import React from "react";
import { LucideProps } from "lucide-react";

interface GradientIconProps extends LucideProps {
  Icon: React.ComponentType<LucideProps>; // The icon component to be rendered
  startColor: string; // Start color for the gradient
  endColor: string; // End color for the gradient
  size?: number; // Size of the icon
}

const GradientIcon: React.FC<GradientIconProps> = ({
  Icon,
  startColor,
  endColor,
  size = 16,
  ...rest
}) => {
  return (
    <svg width={size} height={size} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id="grad1" x1="100%" y1="80" x2="0%" y2="80" gradientUnits="userSpaceOnUse">
          <stop offset="0%" style={{ stopColor: startColor, stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: endColor, stopOpacity: 1 }} />
        </linearGradient>
      </defs>
      <Icon size={size} stroke="url(#grad1)" {...rest} />
    </svg>
  );
};

export default GradientIcon;
