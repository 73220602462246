import { NavLink, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/svg/admin-logo.svg";
import { onLogout } from "../../api-client/api_client";
import {
  ADMIN_ROOT_PATH,
  INTEGRATION_DASHBOARD_PATH,
  ALERTS_DASHBOARD_PATH,
} from "../../router/RouterUtils";
import { ArrowUpRight, LogOut } from "lucide-react";
import {
  Badge,
  Button,
  ButtonVariant,
  Tab,
  Tabs,
  TabsList,
} from "@merge-api/merge-javascript-shared";
import { getAlertCount } from "./utils/helpers";
import { useState } from "react";

const Nav = styled.nav.attrs({
  className: "px-8 py-3 border-gray-10 border-b-[1px] flex flex-row items-center bg-white z-50",
})`
  && {
    background: white;
    width: 100%;
    position: fixed;
  }
`;

function NavBar({ isAlertTabEnabled }: { isAlertTabEnabled: boolean | undefined }) {
  const history = useHistory();
  const location = useLocation();
  const logout = () =>
    onLogout(history, { onError: () => console.error("Failed to logout. Please try again.") });
  const ALERTS_TAB_VALUE = "alerts";
  const [alertCount, setAlertCount] = useState<number | null>(null);
  if (isAlertTabEnabled) {
    getAlertCount(setAlertCount);
  }

  const tabData = [
    {
      label: "Integrations",
      value: "integrations",
      destination: INTEGRATION_DASHBOARD_PATH,
    },
    ...(isAlertTabEnabled
      ? [
          {
            label: "Alerts",
            value: ALERTS_TAB_VALUE,
            destination: ALERTS_DASHBOARD_PATH,
          },
        ]
      : []),
  ];

  const buttonData = [
    {
      onClick: () => window.open("https://www.merge.dev"),
      title: "Landing",
      rightIcon: <ArrowUpRight className="space-x-1" size="16" />,
    },
    {
      onClick: () => window.open("https://app.merge.dev/"),
      title: "Dashboard",
      rightIcon: <ArrowUpRight className="space-x-1" size="16" />,
    },
    {
      onClick: () => window.open("https://docs.merge.dev/"),
      title: "Docs",
      rightIcon: <ArrowUpRight className="space-x-1" size="16" />,
    },
    {
      onClick: logout,
      title: "Logout",
      rightIcon: <LogOut className="space-x-1" size="16" />,
    },
  ];

  const findCurrentTab = () => {
    const matchingTab = tabData.find((tab) => location.pathname === tab.destination);
    return matchingTab ? matchingTab.value : null;
  };

  const onTabChange = (_: any, newValue: string | number | null) => {
    if (typeof newValue === "string") {
      const selectedTab = tabData.find((tab) => tab.value === newValue);
      if (selectedTab && selectedTab.destination) {
        history.push(selectedTab.destination);
      }
    }
  };

  return (
    <Nav>
      <NavLink to={ADMIN_ROOT_PATH}>
        <img src={Logo} className="h-5" />
      </NavLink>

      <ul className="sub-navbar-nav-side m-0 p-0 flex-fill flex-row justify-end">
        <Tabs value={findCurrentTab()} variant="button" onChange={onTabChange}>
          <TabsList className="border-b-0">
            {tabData.map((tab) => (
              <Tab className="whitespace-nowrap" key={tab.value} value={tab.value}>
                {tab.label}
                {tab.value === ALERTS_TAB_VALUE ? (
                  <Badge className="ml-2" size="sm">
                    {alertCount}
                  </Badge>
                ) : null}
              </Tab>
            ))}
          </TabsList>
        </Tabs>

        {buttonData.map((button) => (
          <Button
            onClick={button.onClick}
            size="md"
            variant={ButtonVariant.TextBlack}
            rightIcon={button.rightIcon}
          >
            {button.title}
          </Button>
        ))}
      </ul>
    </Nav>
  );
}

export default NavBar;
