import { ButtonVariant, Menu, MenuItem } from "@merge-api/merge-javascript-shared";
import { handleResolveAction, handleSilenceAction } from "../utils/alertActionHelpers";
import { AdminAlert } from "../../../../../models/Entities";
import { Dispatch, SetStateAction } from "react";

interface DrawerAlertActionCellProps {
  alert: AdminAlert;
  updateAdminAlert: Dispatch<SetStateAction<Record<string, any>>>;
}

const DrawerAlertActionCell = ({ alert, updateAdminAlert }: DrawerAlertActionCellProps) => {
  const isResolved = !!alert.resolved_at;
  const resolveButtonText = !isResolved ? "Resolve" : "Unresolve";
  const isSilenced = !!alert.silenced_at;

  return (
    <div className="flex justify-end items-center space-x-5">
      <div
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Menu
          ButtonProps={{
            children: resolveButtonText,
            variant: ButtonVariant.TertiaryWhite,
            size: "sm",
            onClick: () => {
              handleResolveAction(alert, isResolved, updateAdminAlert);
            },
          }}
          isComboButton
          menuPlacement="bottom-end"
          menuClassName="z-[1400]"
        >
          <MenuItem onClick={() => handleSilenceAction(alert, isSilenced, updateAdminAlert)}>
            {isSilenced ? "Mark as Active" : "Mark as Noise"}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default DrawerAlertActionCell;
