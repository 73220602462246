import { Badge, Text, Card, Link } from "@merge-api/merge-javascript-shared";
import { constructKibanaLinkFromAlertErrorCode } from "../utils/kibanaHelpers";
import { AdminAlert } from "../../../../../models/Entities";
import { ExternalLink } from "lucide-react";

interface AlertDashboardDrawerErrorCodeDataProps {
  alert: AdminAlert;
  errorCodeCounts: Record<string, number>;
}

const AlertDashboardDrawerErrorCodeData = ({
  alert,
  errorCodeCounts,
}: AlertDashboardDrawerErrorCodeDataProps) => {
  return (
    <div className="mb-6">
      <Text variant="h4" className="mb-5">
        Error codes
      </Text>
      <div className="grid grid-cols-1 gap-4">
        {Object.entries(errorCodeCounts).map(([code, count]) => (
          <Card className="border rounded-md p-4 flex justify-between items-center">
            <div className="flex flex-col gap-1 w-full">
              <Badge size="lg" color="yellow" className="self-start">
                <Text variant="h6">{code}</Text>
              </Badge>
              <Text variant="sm" className="text-gray-50">
                Count: {count}
              </Text>
            </div>
            <div className="flex items-center text-blue">
              <Link
                variant="h6"
                href={constructKibanaLinkFromAlertErrorCode(alert, code)}
                className="flex items-center gap-1"
                target="_blank"
              >
                Kibana
                <ExternalLink size={16} />
              </Link>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default AlertDashboardDrawerErrorCodeData;
