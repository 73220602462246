import { Text } from "@merge-api/merge-javascript-shared";
import { CloudMoon, Frown } from "lucide-react";
import GradientIcon from "../../../../components/shared/icons/GradientIcon";

interface AlertDashboardEmptyTableProps {
  columnCount: number; // To span across columns
  usingSearchFilters: boolean;
}

const AlertDashboardEmptyTable = ({
  columnCount,
  usingSearchFilters,
}: AlertDashboardEmptyTableProps) => {
  const content = usingSearchFilters
    ? {
        icon: Frown,
        heading: "No results found",
        subtext: "Clear your filters or adjust them to find results",
      }
    : {
        icon: CloudMoon,
        heading: "No alerts",
        subtext: "Go take a break!",
      };

  return (
    <tr>
      <td colSpan={columnCount} className="p-4">
        <div className="flex flex-col items-center justify-center min-h-[280px] w-full h-full space-y-1">
          <GradientIcon Icon={content.icon} size={40} endColor="#2DB9EE" startColor="#0042E5" />
          <Text variant="h5" className="text-gray-50">
            {content.heading}
          </Text>
          <Text variant="sm" className="text-gray-50">
            {content.subtext}
          </Text>
        </div>
      </td>
    </tr>
  );
};

export default AlertDashboardEmptyTable;
